import { v4 } from 'uuid';

export const useQuizTeam = defineStore('quizTeam', () => {
  /**
   * State
   */
  const teams = ref<Team[]>([]);
  const suffixes = ['A', 'B', 'C', 'D', 'E'];

  /**
   * Getters
   */
  const byId = (teamId: string): Promise<Team | undefined> =>
    database.quiz_teams.where('id').equals(teamId).first();
  const bySubmissionId = (submissionId: string): Promise<Team[]> =>
    database.quiz_teams.where('submission_id').equals(submissionId).toArray();
  const size = (amount: number): TeamSize => {
    if (amount <= 10) return 'ok';
    if (amount === 11) return 'warning';

    return 'too-big';
  };
  const hasTeams = async (submissionId: string): Promise<boolean> => {
    const count = await database.quiz_teams
      .where({ submission_id: submissionId })
      .count();

    return count > 0;
  };
  const suffix = (index: number): string => suffixes[index - 1];
  const canProceed = () =>
    teams.value.filter((t) => size(t.children.length) !== 'too-big').length ===
    teams.value.length;
  const canCreateTeam = (): boolean => teams.value.length < 4;

  /**
   * Methods
   */
  const advice = (childrenCount: number): TeamIndex => {
    if (childrenCount <= 7) return 1;
    if (childrenCount <= 14) return 2;
    if (childrenCount <= 33) return 3;

    return 4;
  };
  const generate = (
    submissionId: string,
    teamAmount: number,
    childIds: string[],
  ): Team[] => {
    // Shuffle the children.
    childIds.sort(() => Math.random() - 0.5);

    const output = childIds.reduce(
      (previous: string[][], current: string, index: number) => {
        const idx = index % teamAmount;
        if (!previous[idx]) previous[idx] = [];
        previous[idx].push(current);
        return previous;
      },
      [],
    );

    teams.value = output.map((ids, index) => ({
      id: v4(),
      children: ids,
      submission_id: submissionId,
      index: (index + 1) as TeamIndex,
      finished_at: null,
    }));

    return teams.value;
  };
  const persist = (submissionId: string): Promise<string> =>
    database.quiz_teams.bulkPut(
      JSON.parse(JSON.stringify(teams.value)).map((team: Team) => ({
        ...team,
        submission_id: submissionId,
      })),
    );
  const load = async (submissionId: string): Promise<void> => {
    teams.value = await database.quiz_teams
      .where({ submission_id: submissionId })
      .toArray();
  };
  const clear = (submissionId: string): Promise<number> =>
    database.quiz_teams.where({ submission_id: submissionId }).delete();
  const finish = (teamId: string): Promise<number> => {
    return database.quiz_teams.update(teamId, {
      finished_at: new Date(),
    });
  };
  const move = (childId: string, teamId: string) => {
    // Remove the child from all teams.
    teams.value.forEach(
      (team) => (team.children = team.children.filter((c) => c !== childId)),
    );

    // Add the child to the requested team.
    const team = teams.value.find((t) => t.id === teamId);
    if (team) team.children.push(childId);

    // Remove empty teams.
    teams.value = teams.value.filter((t) => t.children.length > 0);

    // Re-index the teams.
    teams.value.forEach(
      (team, index) => (team.index = (index + 1) as TeamIndex),
    );
  };
  const addTeam = (submissionId: string): Team => {
    const team = {
      id: v4(),
      children: [],
      submission_id: submissionId,
      index: Math.max(...teams.value.map((t) => t.index)) + 1,
      finished_at: null,
    } as Team;

    teams.value.push(team);

    return team;
  };

  /**
   * Actions
   */

  return {
    // State
    teams,

    // Getters
    size,
    byId,
    suffix,
    hasTeams,
    canProceed,
    canCreateTeam,
    bySubmissionId,

    // Methods
    load,
    move,
    clear,
    advice,
    finish,
    persist,
    addTeam,
    generate,
  };
});
